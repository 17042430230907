type Direction = 'right' | 'left'

type Props = {
  direction: Direction
  darkTheme?: boolean
}

const getRotateDeg = (direction: Direction) =>
  ({
    right: '',
    left: 'rotate-180'
  }[direction])

export default function GalleryArrow({ direction, darkTheme }: Props) {
  const stroke = darkTheme ? 'black' : 'white'

  return (
    <div className={`transform ${getRotateDeg(direction)}`}>
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle opacity="0.2" cx="40" cy="40" r="39.5" stroke={stroke} />
        <path
          d="M36.8745 32.5L44.0205 39.6467C44.067 39.693 44.1038 39.7481 44.1289 39.8087C44.1541 39.8694 44.167 39.9344 44.167 40C44.167 40.0656 44.1541 40.1306 44.1289 40.1913C44.1038 40.2519 44.067 40.307 44.0205 40.3533L36.8745 47.5"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}
