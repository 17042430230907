import { useRef, useState } from 'react'
import Image from 'next/image'

import GalleryArrow from '~/components/icons/IconGalleryArrow'

export default function ImageGallery(props) {
  const [imageGalleryPosition, setImageGalleryPosition] = useState(0)
  const imageGallerySectionRef = useRef(null)
  const imageGalleryContainerRef = useRef(null)

  const handleGalleryScroll = (direction: 'left' | 'right') => {
    const multiplier = direction === 'left' ? -1 : 1
    const imageGallerySectionWidth = imageGallerySectionRef.current.offsetWidth
    const imageGalleryContainerRefWidth =
      imageGalleryContainerRef.current.offsetWidth
    var scrollPosition =
      imageGalleryPosition + (imageGallerySectionWidth / 3) * multiplier
    if (scrollPosition > imageGalleryContainerRefWidth * 1.5) {
      scrollPosition = imageGalleryContainerRefWidth * 1.5
    } else if (scrollPosition < 0) {
      scrollPosition = 0
    }
    setImageGalleryPosition(scrollPosition)
    document
      .querySelector('#imageGallery')
      .scrollTo({ left: scrollPosition, behavior: 'smooth' })
  }

  return (
    <section
      ref={imageGallerySectionRef}
      className="flex flex-col lg:pb-52 lg:pt-40 max-w-7xl w-full"
    >
      <h2 className="text-white font-medium text-xl md:text-4xl pb-9 lg:pb-24 lg:text-center">
        Consistently Simple
      </h2>
      <div className="relative">
        <div
          className="absolute left-0 top-1/2 transform -translate-y-1/2 z-20 xl:-ml-8 cursor-pointer hidden md:block pl-4 xl:pl-0"
          onClick={() => handleGalleryScroll('left')}
        >
          <GalleryArrow direction="left" />
        </div>
        <div
          className="absolute right-0 top-1/2 transform -translate-y-1/2 z-20 xl:-mr-8 cursor-pointer hidden md:block pr-4 xl:pr-4"
          onClick={() => handleGalleryScroll('right')}
        >
          <GalleryArrow direction="right" />
        </div>
        <div className="hidden md:block pointer-events-none absolute top-0 bottom-0 right-0 w-64 bg-gradient-to-l from-grey-700 z-10"></div>
        <div
          id="imageGallery"
          ref={imageGalleryContainerRef}
          className="overflow-x-scroll flex flex-row items-center -mx-6 md:mx-0"
        >
          <div className="pl-6 xl:hidden"></div>
          {props.data.image_gallery.map((image, index) => (
            <div
              className={`flex-grow-0 flex-shrink-0 ${
                index > 0 ? 'md:ml-12' : ''
              } w-5/6 md:w-2/5 `}
              key={image}
            >
              <div className={`hidden md:block`}>
                <Image
                  src={image.src}
                  width="100%"
                  height="100%"
                  layout="responsive"
                  objectFit="contain"
                  alt="Gallery Image"
                />
              </div>
              <div className={`md:hidden`}>
                <Image
                  src={image.src}
                  width="100%"
                  height="100%"
                  layout="responsive"
                  objectFit="contain"
                  alt="Gallery Image"
                />
              </div>
            </div>
          ))}
        </div>
        <style jsx>{`
          ::-webkit-scrollbar {
            width: 0px;
          }
          ::-webkit-scrollbar-track {
            background: #030303;
          }
          ::-webkit-scrollbar-thumb {
            background: #030303;
          }
        `}</style>
      </div>
    </section>
  )
}
