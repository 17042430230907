import NotchedCard from '~/components/NotchedCard'
import Feature1 from '~/svg/model-z-feature1.svg'
import Feature2 from '~/svg/model-z-feature2.svg'
import Feature3 from '~/svg/model-z-feature3.svg'
import Feature4 from '~/svg/model-z-feature4.svg'
import Feature5 from '~/svg/model-z-feature5.svg'
import AppFeature1 from '~/svg/app-feature1.svg'
import AppFeature2 from '~/svg/app-feature2.svg'
import AppFeature3 from '~/svg/app-feature3.svg'
import AppFeature4 from '~/svg/app-feature2.svg'

const modelZ = [Feature1, Feature2, Feature3, Feature4, Feature5]
const app = [AppFeature1, AppFeature2, AppFeature3, AppFeature4]

const images = {
  modelZ,
  app
}

type Feature = {
  title: string
  description: string
}

type Props = {
  features: Feature[]
  type: 'modelZ' | 'app'
}

export default function Features({ features, type }: Props) {
  return (
    <div className="max-w-7xl mx-auto">
      <h2 className="text-white font-medium text-lg lg:text-2xl pb-9 pl-6 lg:pl-0">
        Features
      </h2>

      <div className="flex flex-row flex-nowrap overflow-x-scroll lg:grid lg:grid-cols-3 lg:pb-24 lg:overflow-x-visible lg:gap-x-5">
        <div className="pl-6 lg:hidden "></div>
        {features.map(({ title, description }, i) => {
          const Icon = images[type][i]
          return (
            <NotchedCard
              notchPosition="top-right"
              className="bg-grey-600 flex flex-row flex-grow-0 flex-shrink-0 w-80 mt-4 rounded-lg text-white px-12 mr-5 lg:mr-0 lg:w-auto"
              key={title + description}
              aspectW={380}
              aspectH={424}
            >
              <div className="flex flex-col">
                <div>
                  <div className="">
                    <Icon className="mt-12" />
                  </div>
                  <h3 className="text-white text-lg pt-12">{title}</h3>
                  <p className="text-sm text-white pt-12 font-sans-serif font-regular pb-10">
                    {description}
                  </p>
                </div>
              </div>
            </NotchedCard>
          )
        })}
      </div>
      <style jsx>{`
        ::-webkit-scrollbar {
          width: 0px;
        }
        ::-webkit-scrollbar-track {
          background: #030303;
        }
        ::-webkit-scrollbar-thumb {
          background: #030303;
        }
      `}</style>
    </div>
  )
}
