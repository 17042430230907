import Link from 'next/link'
import Image from 'next/image'

import GooglePlay from '~/svg/google-play.svg'
import AppleStore from '~/svg/apple-store.svg'

export default function AppCTAs() {
  return (
    <div className="mx-auto max-w-7xl p-6 flex flex-col gap-x-6 gap-y-16 my-6 md:flex-row md:justify-between">
      <div className="md:w-1/2 md:pt-24">
        <p className="font-medium text-white">Simple, Effortless, Automatic</p>
        <h3 className="text-xl font-medium leading-10 text-white mt-12 md:mt-16 md:text-2xl">
          We Created A Companion App Designed To Automate Your Growing
          Experience
        </h3>
        <div className="flex justify-center gap-x-4 mt-9 md:justify-start md:mt-12">
          <Link href="https://play.google.com/store/apps/details?id=com.mary">
            <a title="Google Play Store | Mary AG" target="_blank">
              <GooglePlay />
            </a>
          </Link>
          <Link href="https://apps.apple.com/app/mary/id1434968352">
            <a title="Apple Store | Mary AG" target="_blank">
              <AppleStore />
            </a>
          </Link>
        </div>
      </div>
      <div
        className="flex md:hidden justify-center relative mx-auto"
        style={{ width: 330 }}
      >
        <div
          className="absolute inset-0"
          style={{
            background: 'radial-gradient(#EA80FF, #1F4FFF)',
            clipPath:
              'polygon(0 20%, 92% 20%, 100% 28%, 100% 100%, 8% 100%, 0 92%)'
          }}
        />
        <Image
          src="/images/mary-app2.png"
          width={280}
          height={400}
          objectFit="contain"
          alt="Mary App"
        />
      </div>
      <div
        className="hidden md:flex justify-center relative"
        style={{ width: 420 }}
      >
        <div
          className="absolute inset-0"
          style={{
            background: 'radial-gradient(#EA80FF, #1F4FFF)',
            clipPath:
              'polygon(0 20%, 92% 20%, 100% 28%, 100% 100%, 8% 100%, 0 92%)'
          }}
        />
        <Image
          src="/images/mary-app2.png"
          width={318}
          height={554}
          objectFit="contain"
          alt="Mary App"
        />
      </div>
    </div>
  )
}
